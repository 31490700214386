import { TYPE_BROADCAST } from './types'
/**
 * Change text only, do not change text inside {{ }}
 */
export const customText = {
  [TYPE_BROADCAST.CUSTOM_ANNOUNCEMENT]: `Yth. Bapak/Ibu/Saudara/i, 
*{{GUEST_NAME}}*

Tanpa mengurangi rasa hormat, berikut link website pemberitahuan kami:
{{LINK}}

Dengan memohon anugerah dan berkat Tuhan, kami memohon doa restu untuk ulang tahun pernikahan ke-50 kami:

*Handoko*
_F.X. Setyo Prihandoko (Sie Ping An)_
&
*Monica* 
_Monica Setyo Hertuti (Tjan Dijen Nio)_

Terima kasih atas segala ucapan dan doa restu yang telah diberikan. 

_Kami yang berbahagia,_
*{{BOY_NAME_SHORT}} & {{GIRL_NAME_SHORT}}*`,

  [TYPE_BROADCAST.CUSTOM_INVITATION]: `Yth. Bapak/Ibu/Saudara/i, 
*{{GUEST_NAME}}*

Tanpa mengurangi rasa hormat, mohon klik link dibawah ini untuk mendapatkan informasi lengkap mengenai acara perayaan ulang tahun pernikahan kami yang ke-50:
{{LINK}}

Dengan memohon anugerah dan berkat Tuhan, kami memohon kehadiran Bapak/Ibu/Saudara/i pada acara perayaan ulang tahun pernikahan ke-50 kami:

*Handoko*
_F.X. Setyo Prihandoko (Sie Ping An)_
&
*Monica* 
_Monica Setyo Hertuti (Tjan Dijen Nio)_

Acara ulang tahun pernikahan kami yang ke-50 akan dilaksanakan pada:
Hari/Tanggal: Sabtu, 25 November 2023
Lokasi: Thamrin Square, Kota Semarang 

Terima kasih atas segala ucapan dan doa restu yang telah diberikan kepada kami.

_Kami yang berbahagia,_
*{{BOY_NAME_SHORT}} & {{GIRL_NAME_SHORT}}*`,

  //Reminder RSVP

    [TYPE_BROADCAST.ID_REMINDER_RSVP] : `Yth. Bapak/Ibu/Saudara/i,  
*{{GUEST_NAME}}*

Melanjutkan undangan pernikahan kami, *{{BOY_NAME}}* & *{{GIRL_NAME}}*, kami ingin mengingatkan Bapak/Ibu/Saudara/i untuk segera mengisi formulir konfirmasi kehadiran (RSVP) yang ada pada website undangan kami:
{{LINK}} 

Status kehadiran Bapak/Ibu/Saudara/i akan bermanfaat untuk kami dalam mempersiapkan acara pernikahan mendatang. 

Terima kasih atas perhatiannya.

*Kami yang berbahagia,*
_{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & keluarga_`,

[TYPE_BROADCAST.EN_REMINDER_RSVP]: `Dear Mr/Mrs/Ms,  
*{{GUEST_NAME}}*

Following up on our wedding invitation, *{{BOY_NAME}}* & *{{GIRL_NAME}}*, we would like to remind you to immediately fill out the attendance confirmation form (RSVP) on our invitation website: 
{{LINK}}

Your attendance status will be very useful for us in preparing the upcoming wedding.

Thank you for your attention

*Yours truly,*
_{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & family_`,

[TYPE_BROADCAST.ID_REMINDER_EVENT]: `Yth. Bapak/Ibu/Saudara/i,  
*{{GUEST_NAME}}*

Melalui pesan ini, kami ingin mengingatkan kembali undangan pernikahan kami, *{{BOY_NAME}}* & *{{GIRL_NAME}}* yang diselenggarakan pada: 

Hari: {{BROADCAST_WEDDING_DAY}}
Waktu: {{TIME}} 
Lokasi: {{BROADCAST_WEDDING_LOCATION}}

Besar harapan kami Bapak/Ibu/Saudara/i berkenan menghadiri pernikahan kami.

Terima kasih 

*Kami yang berbahagia,*
_{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & family_`,

[TYPE_BROADCAST.EN_REMINDER_EVENT]: `Dear Mr/Mrs/Ms,  
*{{GUEST_NAME}}*

Through this message, we would like to remind you of our wedding invitation, *{{BOY_NAME}}* & *{{GIRL_NAME}}* which will be held on:

Date: {{BROADCAST_WEDDING_DAY}}
Time: {{TIME}}
Place: {{BROADCAST_WEDDING_LOCATION}}

We hope that you are willing to attend our wedding.

Thank you for your attention

*Yours truly,*
_{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & family_`,

[TYPE_BROADCAST.ID_THANKS]: `Yth. Bapak/Ibu/Saudara/i,  
*{{GUEST_NAME}}*

Kami, *{{BOY_NAME}}* & *{{GIRL_NAME}}* dan keluarga ingin mengucapkan terima kasih atas kesediaan Bapak/Ibu/Saudara/i untuk hadir dalam acara pernikahan kami. 

Kehadiran Bapak/Ibu/Saudara/i tentunya telah menjadi kebahagiaan untuk kami & keluarga. Kami juga ingin memohon maaf jika selama berlangsungnya acara terdapat hal-hal yang kurang berkenan. 
 
Terima kasih atas segala ucapan, doa, dan perhatian yang diberikan. 

*Kami yang berbahagia,*
_{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & keluarga_`,

[TYPE_BROADCAST.EN_THANKS]: `Dear Mr/Mrs/Ms,  
*{{GUEST_NAME}}*

We, *{{BOY_NAME}}* & *{{GIRL_NAME}}* and family would like to thank you for your willingness to attend our wedding.

Your presence has certainly been a joy for us & our family. We also want to apologize if during the event there are things that are not pleasing.
 
Thank you for all the words, prayers, and attention given.

*Yours truly,*
_{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & family_`,
}